import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { identifiers } from "../../../helpers/constants/identifier";
import {
  List,
  ListItemButton,
  ListItemText,
  Collapse,
  Box,
  Button,
  Typography,
} from "@mui/material";
import moment from "moment-timezone";
import { Config, fetchFromStorage } from "src/helpers/context";
import { useDispatch } from "react-redux";
import _ from "lodash";

import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { ReactComponent as Facebook } from "../../../assets/images/icons/facebook.svg";
import { ReactComponent as Snapchat } from "../../../assets/images/icons/snapchat-logo.svg";
import { ReactComponent as Instagram } from "../../../assets/images/icons/instagram.svg";
import { ReactComponent as Linkedin } from "../../../assets/images/icons/linkedin.svg";
import { ReactComponent as Twitter } from "../../../assets/images/icons/twitter.svg";
import { ReactComponent as TwitterX } from "../../../assets/images/icons/TwitterX.svg";
import { ReactComponent as Youtube } from "../../../assets/images/youtube.svg";
import { ReactComponent as Rumble } from "../../../assets/images/icons/rumble.svg";
import TikTok from "../../../assets/images/tiktok.png";
import "./leftsidebar.scss";
import DialogBox from "../UI/dialogBox";
import { element } from "prop-types";
import { fetchRestrictedRoute } from "src/helpers/store/Actions/RestrictedRoute";

export default function LeftSideBar({ handleCloseMenu }) {
  const location = useLocation();
  const events = useSelector((state) => state?.reduxData);
  const userData = useSelector((state) => state?.reduxData?.SubscripitionData);
  const [openSubmenu, setOpenSubmenu] = useState("");
  const [subMenuOpen, setsubMenuOpen] = useState(true);
  const [subMenuClose, setsubMenuClose] = useState(false);
  const [secondSubMenu, setSecondSubMenu] = useState(false);
  const [racingSubMenu, setRacingSubMenu] = useState(false);
  const [cricketData, setCricketData] = useState([]);
  const [RLData, setRLData] = useState([]);
  const [RUData, setRUData] = useState([]);
  const [NBAData, setNBAData] = useState([]);
  const [AFLData, setAFLData] = useState([]);
  const [ARData, setARData] = useState([]);
  const [TennisData, setTennisData] = useState([]);
  const [BaseballData, setBaseballData] = useState([]);
  const [IceHockeyData, setIceHockeyData] = useState([]);
  const [BoxingData, setBoxingData] = useState([]);
  const [MMAData, setMMAData] = useState([]);
  const [SoccerData, setSoccerData] = useState([]);
  const [EventData, setEventData] = useState([]);
  const [MenuItems, setMenuItems] = useState(identifiers?.Menu);
  const history = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    // if (location.pathname.includes("/racing")) {
    //   setOpenSubmenu("Racing");
    //   setsubMenuClose(false)
    // } else if (location.pathname.includes(
    //   "/responsible-gambling"
    // ) ||
    //   location.pathname.includes("/privacy-policy") ||
    //   location.pathname.includes(
    //     "/terms-and-conditions"
    //   )) {
    //   setOpenSubmenu("Policies");
    //   setsubMenuClose(false);
    // } else { }
  }, [location.pathname]);
  // useEffect(() => {
  //   if (events?.CricketData) {
  //     // setCricketData(
  //     //   [{
  //     //     name: "cricket",
  //     //     isData: events?.CricketData?.length > 0
  //     //   }]
  //     // );
  //     var cricket = [
  //       {
  //         name: "cricket",
  //         isData: events?.CricketData?.length > 0,
  //       },
  //     ];
  //   } else {
  //     cricket = [
  //       {
  //         name: "cricket",
  //         isData: false,
  //       },
  //     ];
  //   }
  //   if (events?.RLData) {
  //     var RL = [
  //       {
  //         name: "rugbyleague",
  //         isData: events?.RLData?.length > 0,
  //       },
  //     ];
  //   } else {
  //     RL = [
  //       {
  //         name: "rugbyleague",
  //         isData: false,
  //       },
  //     ];
  //   }
  //   if (events?.RUData) {
  //     var RU = [
  //       {
  //         name: "rugbyunion",
  //         isData: events?.RUData?.length > 0,
  //       },
  //     ];
  //   } else {
  //     RU = [
  //       {
  //         name: "rugbyunion",
  //         isData: false,
  //       },
  //     ];
  //   }
  //   if (events?.AFData) {
  //     var AF = [
  //       {
  //         name: "americanfootball",
  //         isData: events?.AFData?.length > 0,
  //       },
  //     ];
  //   } else {
  //     AF = [
  //       {
  //         name: "americanfootball",
  //         isData: false,
  //       },
  //     ];
  //   }
  //   if (events?.ARData) {
  //     var AR = [
  //       {
  //         name: "australianrules",
  //         isData: events?.ARData?.length > 0,
  //       },
  //     ];
  //   } else {
  //     AR = [
  //       {
  //         name: "australianrules",
  //         isData: false,
  //       },
  //     ];
  //   }
  //   if (events?.BaseballData) {
  //     var Baseball = [
  //       {
  //         name: "baseball",
  //         isData: events?.BaseballData?.length > 0,
  //       },
  //     ];
  //   } else {
  //     Baseball = [
  //       {
  //         name: "baseball",
  //         isData: false,
  //       },
  //     ];
  //   }
  //   if (events?.BasketballData) {
  //     var Basketball = [
  //       {
  //         name: "basketball",
  //         isData: events?.BasketballData?.length > 0,
  //       },
  //     ];
  //   } else {
  //     Basketball = [
  //       {
  //         name: "basketball",
  //         isData: false,
  //       },
  //     ];
  //   }
  //   if (events?.BoxingData) {
  //     var Boxing = [
  //       {
  //         name: "boxing",
  //         isData: events?.BoxingData?.length > 0,
  //       },
  //     ];
  //   } else {
  //     Boxing = [
  //       {
  //         name: "boxing",
  //         isData: false,
  //       },
  //     ];
  //   }
  //   if (events?.GolfData) {
  //     var Golf = [
  //       {
  //         name: "golf",
  //         isData: events?.GolfData?.length > 0,
  //       },
  //     ];
  //   } else {
  //     Golf = [
  //       {
  //         name: "golf",
  //         isData: false,
  //       },
  //     ];
  //   }
  //   if (events?.IceHockeyData) {
  //     var IceHockey = [
  //       {
  //         name: "icehockey",
  //         isData: events?.IceHockeyData?.length > 0,
  //       },
  //     ];
  //   } else {
  //     IceHockey = [
  //       {
  //         name: "icehockey",
  //         isData: false,
  //       },
  //     ];
  //   }
  //   if (events?.MMAData) {
  //     var MMA = [
  //       {
  //         name: "mma",
  //         isData: events?.MMAData?.length > 0,
  //       },
  //     ];
  //   } else {
  //     MMA = [
  //       {
  //         name: "mma",
  //         isData: false,
  //       },
  //     ];
  //   }
  //   if (events?.SoccerData) {
  //     var Soccer = [
  //       {
  //         name: "soccer",
  //         isData: events?.SoccerData?.length > 0,
  //       },
  //     ];
  //   } else {
  //     Soccer = [
  //       {
  //         name: "soccer",
  //         isData: false,
  //       },
  //     ];
  //   }
  //   if (events?.TennisData) {
  //     var Tennis = [
  //       {
  //         name: "tennis",
  //         isData: events?.TennisData?.length > 0,
  //       },
  //     ];
  //   } else {
  //     Tennis = [
  //       {
  //         name: "tennis",
  //         isData: false,
  //       },
  //     ];
  //   }
  //   if (
  //     events?.CricketData &&
  //     events?.RLData &&
  //     events?.RUData &&
  //     events?.AFData &&
  //     events?.ARData &&
  //     events?.BaseballData &&
  //     events?.BasketballData &&
  //     events?.BoxingData &&
  //     events?.GolfData &&
  //     events?.IceHockeyData &&
  //     events?.MMAData &&
  //     events?.SoccerData &&
  //     events?.TennisData
  //   ) {
  //     let mergeData = [
  //       ...cricket,
  //       ...RL,
  //       ...RU,
  //       ...AR,
  //       ...AF,
  //       ...Baseball,
  //       ...Basketball,
  //       ...Boxing,
  //       ...Golf,
  //       ...IceHockey,
  //       ...MMA,
  //       ...Soccer,
  //       ...Tennis,
  //     ];
  //     setEventData(mergeData);
  //   }
  // }, [events]);

  // useEffect(() => {
  //   if (EventData?.length > 0) {
  //     let fixtureDataMenu = MenuItems?.map((item, index) => {
  //       if (index === 2) {
  //         return {
  //           ...item,
  //           submenu: item?.submenu?.map((obj) => {
  //             return {
  //               ...obj,
  //               eventData: EventData?.filter((element) => {
  //                 return obj?.routeName === element?.name;
  //               }),
  //             };
  //           }),
  //         };
  //       }
  //       return item;
  //     });

  //     let filterMenu = fixtureDataMenu?.map((item, index) => {
  //       if (index === 2) {
  //         return {
  //           ...item,
  //           submenu: item?.submenu?.filter((obj) => {
  //             return obj?.eventData?.[0]?.isData === true;
  //           }),
  //         };
  //       }
  //       return item;
  //     });
  //     setMenuItems(filterMenu);
  //   }
  // }, [EventData]);

  // OPEN SUBMENUS
  const handleOpenSubmenu = (openColleps, url) => {
    setOpenSubmenu(openSubmenu !== openColleps ? openColleps : "");
    setsubMenuOpen(openSubmenu !== openColleps ? true : !subMenuOpen);
    setsubMenuClose(subMenuOpen === false ? true : false);
  };
  const handleRestrictedUser = () => {
    const localAuth = fetchFromStorage("auth_token");
    let isLogin = localAuth ? true : false;
    return isLogin;
  };

  // OPEN SUBMENUS
  const handleNavigate = (Navigate, data) => {
    if (
      (data?.parentId === 10 && data?.id === 2) ||
      (data?.parentId === 10 && data?.id === 3) ||
      (data?.parentId === 10 && data?.id === 5) ||
      (data?.parentId === 2 && data?.id === 6)
      // ||
      // (data?.parentId === 3 && data?.id === 99)
    ) {
      if (Navigate?.url) {
        if ((data?.parentId === 10 && data?.id === 5) || data?.id === 6) {
          if (handleRestrictedUser()) {
            data?.id === 5 && (window.location.href = Navigate?.url);
            data?.id === 6 && history(Navigate?.url);
          } else {
            // handleModal()
            dispatch(fetchRestrictedRoute(true));
            localStorage.setItem("prev_authroute", Navigate?.url);
            if (handleCloseMenu) {
              handleCloseMenu();
            }
          }
        } else {
          window.location.href = Navigate?.url;
        }
      }
    } else {
      if (
        // data?.parentId === 9 ||
        // data?.parentId === 3 ||
        data?.sportParentId === 98 ||
        (Navigate?.id === 99 && data?.parentId === 3) ||
        (data?.parentId === 11 && Navigate?.name === "Racing Blackbook") ||
        (data?.parentId === 11 && Navigate?.name === "Sports Smartbook") ||
        (data?.parentId === 12 && Navigate?.name === "Create Comps") ||
        (data?.parentId === 12 && Navigate?.name === "My Comps") ||
        (data?.parentId === 12 && Navigate?.name === "Tipping FAQs") ||
        (data?.parentId === 12 && Navigate?.name === "Prizes") ||
        (data?.parentId === 10 && data?.id === 11) ||
        (data?.parentId === 10 && data?.id === 12) ||
        (Navigate?.raceParentId === 2 && Navigate?.id === 100)
        // ||
        // (data?.parentId === 12 && Navigate?.name === "Rankings")
        //  ||
        // (data?.parentId === 10 && data?.id === 4)
      ) {
        if (handleRestrictedUser()) {
          if (Navigate?.url) {
            Navigate?.raceParentId === 2 && Navigate?.id === 100
              ? history(Navigate?.url, { state: true })
              : history(Navigate?.url);
          }
        } else {
          if (handleCloseMenu) {
            handleCloseMenu();
          }
          if (Navigate?.raceParentId === 2 && Navigate?.id === 100) {
            history("/soc-signup");
          } else {
            dispatch(fetchRestrictedRoute(true));
            localStorage.setItem("prev_authroute", Navigate?.url);
          }
          // handleModal()
        }
      } else {
        if (
          !handleRestrictedUser() &&
          Navigate?.parentId === 10 &&
          Navigate?.id === 10
        ) {
          history("/soc-signup");
        } else {
          history(Navigate?.url);
        }
      }
    }
  };
  useEffect(() => {
    // setOpenSubmenu(
    //   location.pathname == "/terms-and-conditions" ||
    //     location.pathname == "/responsible-gambling" ||
    //     location.pathname == "/privacy-policy"
    //     ? "Policies"
    //     : " "
    // );
  }, []);

  let searchParamsType = new URLSearchParams(window.location.search).get(
    "type"
  );
  const params = useParams();

  // useEffect(() => {
  //   if (
  //     userData &&
  //     userData?.id &&
  //     JSON.parse(Config?.subscriptionUserID)?.includes(userData?.id)
  //   ) {
  //     return setMenuItems(identifiers?.Menu);
  //   } else {
  //     const newMenuItems = MenuItems?.map((menuI) => {
  //       if (menuI?.name === "Information") {
  //         return {
  //           ...menuI,
  //           submenu: menuI?.submenu?.filter(
  //             (item) =>
  //               item?.name !== "Subscription" &&
  //               item?.name !== "My Subscription"
  //           ),
  //         };
  //       }
  //       return menuI;
  //     });
  //     setMenuItems(newMenuItems);
  //   }
  // }, [userData]);
  return (
    <>
      <List className="menu-wrap">
        {MenuItems?.map((item) => {
          return (
            <React.Fragment key={item?.name}>
              <Box>
                <Box onClick={() => handleNavigate(item)}>
                  <ListItemButton
                    button
                    selected={
                      location.pathname != "/comingsoon"
                        ? location.pathname.includes("/racing")
                          ? item?.url == "/racing"
                          : item?.url == location.pathname ||
                            ((location.pathname.includes(
                              "/responsible-gambling"
                            ) ||
                              location.pathname.includes("/privacy-policy") ||
                              location.pathname.includes(
                                "/terms-and-conditions"
                              )) &&
                              item?.id == 8) ||
                            (location.pathname.includes(
                              "/statistics/trackprofiles"
                            ) &&
                              item?.id == 9) ||
                            (location.pathname.includes("/teamsports") &&
                              item?.id == 3) ||
                            (location.pathname.includes("/news") &&
                              item?.id == 10) ||
                            (location.pathname.includes("/odds-comparison") &&
                              item?.id == 100)
                        : ""
                    }
                    className={
                      item?.name === "Home" ||
                      (location.pathname.includes("/odds-comparison") &&
                        item?.id == 100)
                        ? "homemenu"
                        : ""
                    }
                    onClick={() => handleOpenSubmenu(item?.name, item?.url)}
                  >
                    <ListItemText className={item?.url ? "" : "cursor-default"}>
                      {item?.name}
                    </ListItemText>
                    {/* {item?.submenu?.length !== 0 ? (
                      openSubmenu == item?.name ? (
                        <ExpandLess className='close' />
                      ) : (
                        // <ExpandMore className="open" />
                        <ExpandLess className='close' />
                      )
                    ) : null} */}
                  </ListItemButton>
                </Box>
                {item?.submenu?.map((obj, index) => {
                  return (
                    <React.Fragment key={index}>
                      <Collapse
                        in={
                          true
                          // (item?.name == openSubmenu
                          //   ? true
                          //   : false) ||
                          // (!(subMenuClose == true && subMenuOpen == true) &&
                          //   (subMenuOpen === true
                          //     ? location.pathname.includes("/racing")
                          //       ? item?.url == "/racing"
                          //       : location.pathname ==
                          //           "/terms-and-conditions" ||
                          //         location.pathname ==
                          //           "/responsible-gambling" ||
                          //         location.pathname == "/privacy-policy"
                          //       ? item?.id === 8
                          //       : location.pathname ==
                          //         "/statistics/trackprofiles"
                          //       ? item?.id === 9
                          //       : location.pathname.includes("/teamsports")
                          //       ? item?.id === 3
                          //       : false
                          //     : false))
                        }
                        // className={
                        //   item.id == 2
                        //     ? obj?.id == searchParamsType ||
                        //       obj?.id == params.sportId
                        //       ? "activesubmenu"
                        //       : ""
                        //     : (obj?.url == location.pathname ||
                        //       location.pathname.includes(obj?.url) ||
                        //       location.pathname.includes(obj?.routeName)) &&
                        //       item?.id === obj.parentId
                        //       ? "activesubmenu"
                        //       : ""
                        // }
                        key={index}
                      >
                        <Box
                          onClick={() => {
                            if (obj?.name == "Show All Sports") {
                              setSecondSubMenu(!secondSubMenu);
                            } else {
                              handleNavigate(obj, obj);
                              setSecondSubMenu(
                                secondSubMenu == true ? false : false
                              );
                            }

                            if (obj?.name == "Statistics") {
                              setRacingSubMenu(!racingSubMenu);
                            } else {
                              handleNavigate(obj, obj);
                              setRacingSubMenu(
                                racingSubMenu == true ? false : false
                              );
                            }
                          }}
                        >
                          <ListItemButton
                            key={obj?.name}
                            button
                            to={obj?.url}
                            // selected={obj?.url === "racing"}
                          >
                            <ListItemText
                              className={
                                item?.id === 2
                                  ? (obj?.url == location.pathname ||
                                      location.pathname.includes(obj?.url) ||
                                      location.pathname.includes(
                                        obj?.routeName
                                      )) &&
                                    item?.id == obj?.parentId
                                    ? "activesubmenu 123"
                                    : item?.id === obj?.raceParentId &&
                                        location.pathname.includes(obj?.url)
                                      ? "activesubmenu"
                                      : !location?.pathname?.includes(
                                            "odds-comparison"
                                          ) &&
                                          (obj?.id == searchParamsType ||
                                            obj?.id == params.sportId)
                                        ? "activesubmenu 456"
                                        : obj?.name == "Show All Sports" ||
                                            obj?.name == "Statistics"
                                          ? "submenu-name second-submenu-name"
                                          : "submenu-name "
                                  : ((obj?.url == location.pathname &&
                                        item?.id == obj?.parentId) ||
                                        location.pathname.includes(obj?.url) ||
                                        location.pathname.includes(
                                          obj?.routeName
                                        )) &&
                                      item?.id === obj?.parentId
                                    ? "activesubmenu"
                                    : obj?.name == "Show All Sports" ||
                                        obj?.name == "Statistics"
                                      ? "submenu-name second-submenu-name"
                                      : "submenu-name "
                              }
                              // className={
                              //   item?.id == 2
                              //     ? obj?.id == searchParamsType ||
                              //       obj?.id == params.sportId
                              //       ? "activesubmenu"
                              //       : obj?.name == "Show All Sports" ||
                              //         obj?.name == "Statistics"
                              //       ? "submenu-name second-submenu-name"
                              //       : "submenu-name "
                              //     : (obj?.url == location.pathname ||
                              //         location.pathname.includes(obj?.url) ||
                              //         location.pathname.includes(
                              //           obj?.routeName
                              //         )) &&
                              //       item?.id == obj?.parentId
                              //     ? "activesubmenu"
                              //     : obj?.name == "Show All Sports" ||
                              //       obj?.name == "Statistics"
                              //     ? "submenu-name second-submenu-name"
                              //     : "submenu-name "
                              // }
                            >
                              {obj?.name}
                              {obj?.secondSubMenu?.length > 0 ? (
                                (
                                  obj?.name == "Statistics"
                                    ? racingSubMenu
                                    : secondSubMenu
                                ) ? (
                                  <ExpandLess className="close" />
                                ) : (
                                  <ExpandMore className="open" />
                                )
                              ) : null}
                            </ListItemText>
                          </ListItemButton>
                        </Box>
                        {obj?.secondSubMenu?.map((sub, subindex) => {
                          return (
                            <React.Fragment key={subindex}>
                              <Collapse
                                in={
                                  obj?.name == "Statistics"
                                    ? racingSubMenu
                                    : secondSubMenu
                                }
                                key={subindex}
                                className="second-menu-collapse"
                              >
                                <Box onClick={() => handleNavigate(sub, sub)}>
                                  <ListItemButton
                                    key={sub?.name}
                                    button
                                    to={sub?.url}
                                  >
                                    <ListItemText
                                      className={
                                        (sub?.url == location.pathname ||
                                          location.pathname.includes(
                                            sub?.url
                                          ) ||
                                          location.pathname.includes(
                                            sub?.routeName
                                          )) &&
                                        obj?.id === sub?.sportParentId
                                          ? "activesubmenu"
                                          : "submenu-name"
                                      }
                                      // className={
                                      //   (sub?.url == location.pathname ||
                                      //     location.pathname.includes(
                                      //       sub?.url
                                      //     ) ||
                                      //     location.pathname.includes(
                                      //       sub?.routeName
                                      //     )) &&
                                      //   obj?.id === sub?.sportParentId
                                      //     ? "activesubmenu"
                                      //     : "submenu-name"
                                      // }
                                    >
                                      {sub?.name}
                                    </ListItemText>
                                  </ListItemButton>
                                </Box>
                              </Collapse>
                            </React.Fragment>
                          );
                        })}
                      </Collapse>
                    </React.Fragment>
                  );
                })}
              </Box>
            </React.Fragment>
          );
        })}
        <Box className="social-media-wrap">
          <Typography> Follow Us </Typography>
          <Box className="social-icon-wrap">
            <a
              href="https://www.instagram.com/smartbapp/"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="instagram"
            >
              <Instagram className="sidebar-icon" />
            </a>
            <Box className="tiktok">
              <a
                href="https://www.tiktok.com/@smartbapp"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="tiktok"
              >
                <img src={TikTok} alt="tiktok" />
              </a>
            </Box>
            <Box className="tiktok youtube">
              <a
                href="https://www.youtube.com/@smartbapp"
                className="youtube"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="youtube"
              >
                <Youtube />
              </a>
            </Box>
            <Box className="tiktok youtube">
              <a
                href="https://www.facebook.com/smartbapp"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="facebook"
              >
                <Facebook className="sidebar-icon" />
              </a>
            </Box>
            {/* <Box className="tiktok youtube snapChat">
              <a
                href="https://t.snapchat.com/m2Cu44hr"
                className="youtube"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="snapchat"
              >
                <Snapchat />
              </a>
            </Box> */}
            {/* <Box className="tiktok youtube snapChat">
              <a
                href=" https://rumble.com/c/c-4732998"
                className="youtube"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="youtube"
              >
                <Rumble />
              </a>
            </Box> */}
            <Box className="tiktok linkedin">
              <a
                href="https://www.linkedin.com/company/smartbapp/"
                className="youtube"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="youtube"
              >
                <Linkedin />
              </a>
            </Box>
            <Box className="tiktok">
              <a
                href="https://twitter.com/backawinneraus"
                className="twitter"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="twitter"
              >
                <TwitterX className="sidebar-icon" />
              </a>
            </Box>
          </Box>
        </Box>
      </List>
    </>
  );
}
