import React, { useContext, useState, useEffect } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { IntlContext } from "src/App";
import {
  Box,
  Button,
  TextField,
  Typography,
  IconButton,
} from "@mui/material";
// import { ReactComponent as VisiblePassword } from "src/assets/images/icons/VisiblePassword.svg";
// import { ReactComponent as UnVisiblePassword } from "src/assets/images/icons/UnVisiblePassword.svg";
import { ReactComponent as CloseIcon } from "src/assets/images/icons/closeicon.svg";
import { NavLink, Link, useNavigate, useLocation } from "react-router-dom";
import logo from "src/assets/images/logo.svg";
import leftimage from "src/assets/images/loginPage.png";
import "../auth.scss";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import Loader from "src/components/Loader";
import { Helmet } from "react-helmet";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

export default function ForgotPassword({ handleModal }) {
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const navigate = useNavigate();
  const location = useLocation();
  const [formvalues, setformvalues] = useState({});
  const [isLoading, setisLoading] = useState(false);
  const [isEmailError, setEmailError] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const handleSendOtp = async () => {
    setisLoading(false);
    try {
      const { status } = await axiosInstance.get(
        `user/forgotPassword/${formvalues?.email}`
      );
      if (status === 200) {
        setisLoading(false);
        navigate("/forgot-password-otp", {
          state: {
            forgotPasswordEmail: formvalues?.email,
            loginEmail: location?.state,
          },
        });
        setEmailError("");
      }
    } catch (err) {
      setisLoading(false);
      console.log("errormsg", err?.response?.data?.message);
      setEmailError(err?.response?.data?.message);
    }
  };
  useEffect(() => {
    window.addEventListener("resize", function() {
      setScreenWidth(window.innerWidth);
    });
  }, []);

  const handleClose = () => {
    navigate("/sign-in", {
      state: {
        loginEmail: location?.state,
      },
    });
  };

  return (<>
    <Helmet>
      <title>SmartB | Forgot Password ? Get New Password to Sign -In</title>
      <meta
        name="description"
        content="Forget a password? SmartB Odds Comparison Platform Compare odds for all matches. Get your new password."
      />
    </Helmet>
    <Box className="auth-sec">
      <Box item md={3} lg={4} className="left-sec hide-mobilemenu">
        <img
          src={leftimage}
          alt="left-banner"
          className="image-sec"
          width="100%"
        />
      </Box>
      <Box item xs={12} sm={12} md={9} lg={8} className="right-sec">
        <Formik
          enableReinitialize
          initialValues={{
            email: location?.state?.otpEmail ? location?.state?.otpEmail : "",
          }}
          validationSchema={Yup.object({
            email: Yup.string()
              .email(localesData?.validation?.valid_email)
              .max(255)
              .required(localesData?.validation?.required_message),
          })}
          onSubmit={() => handleSendOtp()}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values,
          }) => (
            <form onSubmit={handleSubmit}>
              {isLoading ? (
                <div className="auth-loader">
                  <Loader />
                </div>
              ) : (
                <></>
              )}
              {screenWidth > 599 ? (
                <>
                  <NavLink to="/" className="hide-mobilemenu">
                    <img
                      src={logo}
                      style={{ maxWidth: " 240.56px" }}
                      className="App-logo-main"
                      alt="logo"
                    />
                  </NavLink>
                  <IconButton
                    disableRipple
                    className="dialog-close"
                    onClick={() => handleClose()}
                    size="large">
                    <CloseIcon />
                  </IconButton>
                </>
              ) : (
                <></>
              )}
              <Typography className="login-title">
                {localesData?.register?.forgot_password}
              </Typography>
              <Box
                className="sign-in-account-sec"
                // onClick={() => {
                //   handleModal("signin");
                // }}
              >
                <Typography
                  onClick={() => handleClose()}
                  className="redirect-link"
                >
                  {localesData?.register?.back_to_signin}
                </Typography>
              </Box>
              <Box className="textfield-sec">
                <Typography className="textfield-text">
                  {localesData?.register?.email}
                </Typography>
                <TextField
                  error={Boolean(touched && errors?.email)}
                  helperText={touched ? errors?.email : ""}
                  placeholder={""}
                  variant="outlined"
                  type="text"
                  name="email"
                  onChange={handleChange}
                  value={values?.email}
                  className="text-field email-text-field"
                />
                <span className="text-danger text-danger-width">
                  {isEmailError}
                </span>
              </Box>
              {setformvalues(values)}
              {screenWidth < 600 && (
                <Box className="prev-next-btn">
                  <Button
                    disableElevation
                    disableFocusRipple
                    disableRipple
                    // type="submit"
                    className="submit-btn"
                    onClick={() => handleClose()}
                  >
                    {localesData?.register?.auth_btn_prev}
                  </Button>
                </Box>
              )}
              <Button
                disableElevation
                disableFocusRipple
                disableRipple
                type="submit"
                className="submit-btn"
                // onClick={() => handleModal("enterotp")} //
              >
                {localesData?.register?.continue}
              </Button>
            </form>
          )}
        </Formik>
      </Box>
    </Box>
  </>);
}
