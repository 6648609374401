import { Box, Button, Icon } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { ReactComponent as ProfileEdit } from "src/assets/images/icons/profile-edit.svg";
import { ReactComponent as BlackBook } from "src/assets/images/icons/profile-blackbook.svg";
import { ReactComponent as Setting } from "src/assets/images/icons/profile-setting.svg";
import { ReactComponent as Logout } from "src/assets/images/icons/logout-icon.svg";
import { ReactComponent as Delete } from "src/assets/images/icons/delete.svg";
import { IntlContext } from "src/App";
import { useNavigate } from "react-router";

const ProfileMenuData = [
  {
    id: 1,
    menuName: "Edit Profile",
    icon: <ProfileEdit />,
    subMenu: [
      {
        id: 11,
        subMenuName: "Update My Details"
      },
      {
        id: 12,
        subMenuName: "Manage Password"
      },
      {
        id: 13,
        subMenuName: "Sports I bet on"
      },
      {
        id: 14,
        subMenuName: "Notification Preferences"
      },
      {
        id: 15,
        subMenuName: "Bookmakers Accounts"
      },
      {
        id: 16,
        subMenuName: "Subscription"
      },
      {
        id: 17,
        subMenuName: "Manage Email Notifications"
      }
    ]
  },
  {
    id: 2,
    menuName: "BlackBook",
    icon: <BlackBook />,
    subMenu: [
      {
        id: 21,
        subMenuName: "BlackBook",
        subName: "RACING"
      },
      {
        id: 22,
        subMenuName: "SmartBook",
        subName: "SPORTS"
      }
    ]
  },
  {
    id: 3,
    menuName: "Customise Homepage",
    icon: <Setting />,
    subMenu: [
      {
        id: 31,
        subMenuName: "Customise"
      }
    ]
  }
];
const ProfileMenu = ({
  selectedMenu,
  handleChangeMenu,
  handleLogout,
  handleuserDelete,
  handleModal
}) => {
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const navigate = useNavigate();
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener("resize", function() {
      setScreenWidth(window.innerWidth);
    });
  }, []);
  const handleNavigate = subMenu => {
    const navigatePath =
      subMenu?.id === 16
        ? `/subscription-payment`
        : subMenu?.id === 17
        ? `/manage-email-subscription`
        : subMenu?.id === 21
        ? `/blackbook`
        : `/smartbook`;
    navigate(navigatePath);
  };
  return (
    <Box className="profile-menu">
      {ProfileMenuData?.map(item => {
        return (
          <Box className="menu-wrap">
            <Box className="profile-menu-name-wrap">
              <span className="edit-icon">{item?.icon}</span>
              <span className="profile-menu-name regular-font semibold-font">
                {item?.menuName}
              </span>
            </Box>
            {item?.subMenu?.map(submenu => {
              return (
                <Box
                  className={`submenu-wrap ${
                    selectedMenu === submenu?.id ? "active-menu" : ""
                  }`}
                  onClick={() => {
                    if (
                      submenu?.id === 16 ||
                      submenu?.id === 17 ||
                      submenu?.id === 21 ||
                      submenu?.id === 22
                    ) {
                      handleNavigate(submenu);
                    } else {
                      handleChangeMenu(
                        submenu?.id,
                        submenu?.subMenuName,
                        submenu?.subName
                      );
                    }
                  }}
                >
                  <span
                    className={`submenu-name regular-font ${
                      item?.id === 1 && submenu?.id === 17
                        ? "width-max-content"
                        : ""
                    }`}
                  >
                    {submenu?.subMenuName}
                  </span>
                  {submenu?.subName && (
                    <span className="regular-small-font">
                      {" "}
                      ({submenu?.subName})
                    </span>
                  )}
                </Box>
              );
            })}
          </Box>
        );
      })}
      {screenWidth > 559 ? (
        <Box className="logout-delete-wrap">
          <Box className="icon-label-wrap" onClick={() => handleLogout()}>
            <span className="icon">
              <Logout />
            </span>{" "}
            <span className="label regular-font semi semibold-font">
              {localesData?.EDITPROFILE?.LOGOUT}
            </span>
          </Box>
          <Box className="icon-label-wrap" onClick={() => handleModal()}>
            <span className="icon">
              <Delete />
            </span>{" "}
            <span className="label regular-font semi semibold-font">
              {localesData?.EDITPROFILE?.DELETE_ACCOUNT}
            </span>
          </Box>
        </Box>
      ) : (
        <Box className="logout-delete-wrap">
          <Box className="profile-button">
            <Box className="profile-btn">
              <Button
                variant="outlined"
                className="btn-logout"
                onClick={() => handleLogout()}
              >
                {localesData?.EDITPROFILE?.LOGOUT}
              </Button>
              <Button
                variant="contained"
                className="btn-delete"
                onClick={() => handleModal()}
              >
                {localesData?.EDITPROFILE?.DEACTIVATE_ACCOUNT}
              </Button>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default ProfileMenu;
