import React, { useEffect, useState, useContext } from "react";
import {
  Box,
  Breadcrumbs,
  Button,
  Typography,
  IconButton,
  Collapse
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import AdvertisingBannner from "../advertisingBanner";
import banner from "../../../assets/images/banner/smart-b-banner-secodary.webp";
import bannerIndia from "../../../assets/images/ad-placeholder/IPL_Page_Banner_1122x218_3.jpg";
import "./profilev2.scss";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import moment from "moment";
import Loader from "../../../components/Loader";
import AdBannner from "../AdBanner/AdBanner";
import { IntlContext } from "src/App";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "src/helpers/store/Actions/auth";
import { ToastContainer, toast } from "react-toastify";
import DialogBox from "../UI/dialogBox";
import { fetchBetSlipToggle } from "src/helpers/store/Actions/BetSlipToggle";
import { fetchBetSlipData } from "src/helpers/store/Actions/BetslipData";
import { fetchBetSlipCount } from "src/helpers/store/Actions/BetslipCount";
import { fetchSubscriptionData } from "src/helpers/store/Actions/SubscriptionData";
import { fetchUpcomingRaces } from "src/helpers/store/Actions/sport";
import profileImage from "src/assets/images/profileImage.png";
import { ReactComponent as EditButton } from "src/assets/images/round-edit-icon.svg";
import { ReactComponent as RightArrow } from "src/assets/images/icons/rightArrow.svg";
import { Config } from "src/helpers/context";
import UploadImageModal from "../UI/uploadImage";
import ProfileMenu from "./ProfileMenu";
import UserEditDetails from "./profileEdit/userEdit";
import PasswordEdit from "./profileEdit/passwordEdit";
import SportsEdit from "./profileEdit/sportsEdit";
import NotificationsEdit from "./profileEdit/notificationsEdit";
import BookmakersEdit from "./profileEdit/bookmakersEdit";
import SubscriptionAndPaymentPage from "../subscriptionAndPayment";
import ProfileSubscription from "./profileEdit/ProfileSubscription";
import CustomiseHomePage from "./profileEdit/customise";
import { fetchLayoutData } from "src/helpers/store/Actions/LayoutData";
import OurPartner from "../ourPartners";

function ProfilePageV2() {
  const navigate = useNavigate();
  let dispatch = useDispatch();
  const ReduxAdsData = useSelector(state => state?.reduxData?.AdsData);
  const reduxSubscriptionData = useSelector(
    state => state?.reduxData?.SubscripitionData
  );
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const [isLoading, setLoading] = useState(false);
  const [userData, setuserData] = useState({});
  const [open, setOpen] = useState(false);
  const [PageHeaderData, setPageHeaderData] = useState([]);
  const [visibleAds, setVisibleAds] = useState([]);
  const [imageModalOpen, setImageModalOpen] = useState(false);
  const [cropImage, setCropImage] = useState(null);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [selectedMenu, setSelectedMenu] = useState(11);
  const [isCollepseOpen, setIsCollepseOpen] = useState(false);
  const [subMenuName, setSubMenuName] = useState("Update My Details");
  const [subName, setSubName] = useState("");

  const handleClickUser = (type, data) => {
    // navigate(`/profile-edit`);
    navigate("/profile-edit", { state: { Data: data, name: type } });
  };

  useEffect(() => {
    handleUserProfile();
    window.addEventListener("resize", function() {
      setScreenWidth(window.innerWidth);
    });
  }, []);

  useEffect(() => {
    if (ReduxAdsData) {
      fetchAdsData(ReduxAdsData);
    }
  }, [ReduxAdsData]);

  const fetchAdsData = ReduxAdsData => {
    let pageheaderAds = ReduxAdsData?.filter(item => item?.page_id === 11);
    setPageHeaderData(pageheaderAds);
  };

  const handleAdVisible = adId => {
    setVisibleAds(prevVisibleAds => [...prevVisibleAds, adId]);
  };

  const fetchPageHeaderAds = (height, placeholder) => {
    if (PageHeaderData?.length > 0) {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={PageHeaderData?.[0]}
          height={height}
          margin={"0px"}
          onAdVisible={handleAdVisible}
          position={1}
        />
      );
    } else {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={[]}
          height={height}
          margin={"0px"}
        />
      );
    }
  };

  const handleUserProfile = async () => {
    setLoading(true);
    // let token =
    //   "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6InVtZXNoLmpuZXh0QGdtYWlsLmNvbSIsImlkIjoxMjgsInJvbGUiOiJtZW1iZXIiLCJpYXQiOjE2NjczOTAzNDAsImV4cCI6MTY2ODExMDM0MH0.mLJKm5-UU8OS0X_cPp5wxjRioKhx_qYSrxqrgL44qrc";
    // const config = {
    //   headers: {
    //     "Content-Type": "application/json",
    //     Authorization: `Bearer ${token}`
    //   }
    // };
    try {
      const { status, data } = await axiosInstance.get("user/profile");
      if (status === 200) {
        setuserData(data);
        setLoading(false);
      } else if (status === 403) {
        setLoading(false);
        localStorage.removeItem("auth_token");
        navigate("/sign-in");
        dispatch(loginUser(""));
      } else if (status === 404) {
        setLoading(false);
        localStorage.removeItem("auth_token");
        navigate("/");
        dispatch(loginUser(""));
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      // localStorage.removeItem("auth_token");
      // navigate("/");
      // dispatch(loginUser(""));
    }
  };
  const handleLogout = () => {
    let Type = localStorage.getItem("type");
    let Url = localStorage.getItem("url");
    localStorage.removeItem("auth_token");
    dispatch(loginUser(""));
    dispatch(fetchBetSlipToggle(false));
    dispatch(fetchBetSlipData([]));
    dispatch(fetchBetSlipCount(0));
    dispatch(fetchSubscriptionData(undefined));
    dispatch(fetchUpcomingRaces(undefined));
    dispatch(fetchLayoutData(null));
    localStorage.removeItem("loginPopupShown");
    const intervalId = localStorage.getItem("popup_intervalId");
    clearTimeout(intervalId);
    localStorage.removeItem("popup_intervalId");
    if (Type && Url) {
      window.location.href = Url;
      localStorage.removeItem("type");
      localStorage.removeItem("url");
      localStorage.setItem("user_status", "logout");
    } else {
      navigate("/");
    }
  };
  const handleuserDelete = async id => {
    setLoading(true);
    try {
      const { status, data } = await axiosInstance.delete(`/user/${id}`);
      if (status === 200) {
        let Type = localStorage.getItem("type");
        let Url = localStorage.getItem("url");
        setLoading(false);
        localStorage.removeItem("auth_token");
        dispatch(loginUser(""));
        toast.success(localesData?.register?.USER_DELETED, {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "colored"
        });

        // let timer = setTimeout(() => {
        if (Type && Url) {
          window.location.href = Url;
          localStorage.removeItem("auth_token");
          dispatch(loginUser(""));
          localStorage.removeItem("type");
          localStorage.removeItem("url");
          localStorage.setItem("user_status", "logout");
        } else {
          localStorage.removeItem("auth_token");
          dispatch(loginUser(""));
          navigate("/");
        }
        // }, 4000);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  let BookMaker = userData?.data?.bookMaker?.filter(item => item !== "Other");

  let newbookMakerOther = userData?.data?.bookMakerOther?.join("");
  let newBookMakerdata =
    newbookMakerOther?.length > 0
      ? BookMaker?.push(newbookMakerOther)
      : BookMaker;

  let sport = userData?.data?.sportOrEvent?.filter(item => item !== "Other");
  let newsportOrEventOther = userData?.data?.sportOrEventOther?.join("");
  let newsportData =
    newsportOrEventOther?.length > 0
      ? sport?.push(newsportOrEventOther)
      : sport;

  let offering = userData?.data?.offerings?.filter(item => item !== "Other");
  let offeringsOther = userData?.data?.offeringsOther?.join("");
  let newofferingData =
    offeringsOther?.length > 0 ? offering?.push(offeringsOther) : offering;

  const fetchNotificationData = data => {
    const SelectedData =
      data?.NotificationPreference?.sportsValue?.length > 0
        ? data?.NotificationPreference?.sportsValue?.filter(
            ele =>
              ele?.tips ||
              ele?.news ||
              ele?.fixtures ||
              ele?.dailyBestBet ||
              ele?.weeklyNewsLetter
          )
        : [];

    const finalData = SelectedData?.filter(
      e => e?.SportId !== 2 && e?.SportId !== 3
    )?.sort((a, b) => a?.Sport?.sportName.localeCompare(b?.Sport?.sportName));

    if (finalData?.length > 0) {
      const notification = finalData?.map((item, index) => {
        let newData = [];
        const offeringTips = item?.tips && newData.push("Tips");
        const offeringNews = item?.news && newData.push("News");
        const offeringFixture =
          item?.fixtures && newData.push("Fixtures & Results");
        const offeringBestBet =
          item?.dailyBestBet && newData.push("Daily Best Bet");
        const weekelyNewsLetter =
          item?.weeklyNewsLetter && newData.push("Weekly Newsletter");
        return (
          <tr key={index}>
            {index === 0 ? (
              <th className="user-details">
                {localesData?.EDITPROFILE?.RECEIVE_NOTIFICATION} <br />
                <span
                  className="profile-edit"
                  onClick={() => handleClickUser("notifications", data)}
                >
                  {localesData?.EDITPROFILE?.EDIT}
                </span>
              </th>
            ) : (
              <th></th>
            )}
            <td>
              <span>
                {item?.SportId === 1 ? "Racing" : item?.Sport?.sportName}:{" "}
              </span>
              {newData?.toString()}
            </td>
          </tr>
        );
      });

      return notification;
    } else {
      return (
        <tr>
          <th className="user-details">
            {localesData?.EDITPROFILE?.RECEIVE_NOTIFICATION} <br />
            <span
              className="profile-edit"
              onClick={() => handleClickUser("notifications", data)}
            >
              {localesData?.EDITPROFILE?.EDIT}
            </span>
          </th>
          <td></td>
        </tr>
      );
    }
  };

  const handleModal = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const fetchHearAboutData = data => {
    let hearedAboutData;
    if (data?.hearedAbout) {
      if (Array.isArray(data?.hearedAbout)) hearedAboutData = data?.hearedAbout;
      else hearedAboutData = JSON.parse(data?.hearedAbout);
    }
    const updatedHearAbout =
      hearedAboutData &&
      hearedAboutData.map(item => {
        if (item?.includes("other")) {
          if (item?.split(":")[1]) {
            return item?.split(":")[1];
          } else {
            return "Other";
          }
        }
        return item;
      });
    return updatedHearAbout?.toString();
  };

  const handleImageModal = () => {
    setImageModalOpen(true);
  };

  const handleImageClose = () => {
    setImageModalOpen(false);
  };

  const getImage = data => {
    setCropImage(data);
    fetchEditProfile(data?.id);
    // setformvalues(prevFormValues => ({
    //   ...prevFormValues,
    //   mediaId: data?.id,
    //   mediaUrl: data?.filePath
    // }));
  };

  const fetchEditProfile = async MediaId => {
    let payload = {
      MediaId
    };

    try {
      const { status, data } = await axiosInstance.put(
        "user/editProfile",
        payload
      );
      if (status === 200) {
        toast.success(data?.message, {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "colored"
        });
        handleUserProfile();
        fetchSubscriptionPaymentData();
      } else {
        toast.error("An Error Occurred", {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "colored"
        });
      }
    } catch (error) {}
  };

  const fetchGender = gender => {
    if (gender == "m") {
      return "Male";
    } else if (gender == "f") {
      return "Female";
    } else if (gender == "nb") {
      return "Non-binary";
    } else if (gender == "pnts") {
      return "Prefer not to say";
    }
  };

  const handleChangeMenu = (value, submenuName, subName) => {
    setSelectedMenu(value);
    setSubName(subName);
    setSubMenuName(submenuName);
    setIsCollepseOpen(false);
  };

  const fetchSelectedComponenet = () => {
    switch (selectedMenu) {
      case 11:
        return (
          <UserEditDetails
            passData={userData?.data}
            handleUserProfile={handleUserProfile}
          />
        );
      case 12:
        return <PasswordEdit data={userData?.data} />;
      case 13:
        return (
          <SportsEdit
            passData={userData?.data}
            handleUserProfile={handleUserProfile}
          />
        );
      case 14:
        return (
          <NotificationsEdit
            passData={userData?.data}
            handleUserProfile={handleUserProfile}
          />
        );
      case 15:
        return (
          <BookmakersEdit
            passData={userData?.data}
            handleUserProfile={handleUserProfile}
          />
        );
      case 16:
        return (
          <ProfileSubscription
            data={userData?.data}
            handleUserProfile={handleUserProfile}
          />
        );
      case 21:
        return (
          <UserEditDetails
            passData={userData?.data}
            handleUserProfile={handleUserProfile}
          />
        );
      case 22:
        return (
          <UserEditDetails
            passData={userData?.data}
            handleUserProfile={handleUserProfile}
          />
        );
      case 31:
        return <CustomiseHomePage userData={userData} isLoading={isLoading} />;

      default:
        return (
          <UserEditDetails
            data={userData?.data}
            handleUserProfile={handleUserProfile}
          />
        );
    }
  };

  const fetchSubscriptionPaymentData = async () => {
    try {
      const { status, data } = await axiosInstance.get(`/user/get-user`);
      if (status === 200) {
        if (Config.release == "IN") {
          data["oddfixtures"] = false;
        }
        dispatch(fetchSubscriptionData(data));
      } else {
        dispatch(fetchSubscriptionData(undefined));
      }
    } catch (err) {
      dispatch(fetchSubscriptionData(undefined));
    }
  };

  return (<>
    <Box className="content-wrap">
      {/* <AdvertisingBannner src={banner} /> */}
      {fetchPageHeaderAds(
        "218px",
        Config?.release == "IN" ? bannerIndia : banner
      )}
      <Box className="profileV2-contanier profile-contanier">
        <Box className="bredcrumn-wrap">
          <Breadcrumbs
            separator="/"
            aria-label="breadcrumb"
            className="breadcrumb"
          >
            <Link underline="hover" color="inherit" to="/">
              HOME
            </Link>

            <Typography>{localesData?.EDITPROFILE?.MY_PROFILE}</Typography>
          </Breadcrumbs>
        </Box>
        {screenWidth > 799 ? (
          <Box className="profile-details">
            <Box className="profile-header-wrap">
              <h3>{localesData?.EDITPROFILE?.MY_PROFILE}</h3>
              <Box
                className="profile-header-icon-wrap"
                onClick={() => handleImageModal()}
              >
                {userData?.data?.Media?.filePath ? (
                  <img
                    className="profile-image"
                    src={
                      Config?.countryMediaURL +
                      userData?.data?.Media?.filePath
                    }
                    alt="profile-image"
                  />
                ) : (
                  <IconButton
                    aria-label="account of current user"
                    aria-controls="primary-search-account-menu"
                    aria-haspopup="true"
                    color="inherit"
                    className="profile-icon"
                    size="large">
                    <PersonIcon />
                  </IconButton>
                )}
                <EditButton className="add-button" />
              </Box>
            </Box>
            <Box className="profile-details-wrap">
              <Box className="profile-left">
                <ProfileMenu
                  selectedMenu={selectedMenu}
                  handleChangeMenu={handleChangeMenu}
                  handleLogout={handleLogout}
                  handleuserDelete={handleuserDelete}
                  handleModal={handleModal}
                />
              </Box>
              <Box className="profile-main-section">
                {fetchSelectedComponenet()}
              </Box>
            </Box>

            {/* {isLoading ? (
              <div className="allsport-loader-center">
                <Loader />
              </div>
            ) : (
              <Box className="details-profile">
                {(reduxSubscriptionData?.address === null ||
                  reduxSubscriptionData?.address?.country === null) && (
                  <Typography className="error-message">
                    You must Add Country to continue!
                  </Typography>
                )}
                {(reduxSubscriptionData?.address === null ||
                  reduxSubscriptionData?.address?.state === null) && (
                  <Typography className="error-message">
                    You must Add State to continue!
                  </Typography>
                )}
                {(reduxSubscriptionData?.dob === "" ||
                  reduxSubscriptionData?.dob === null) && (
                  <Typography className="error-message">
                    You must Add DOB to continue!
                  </Typography>
                )}

                <table>
                  <tbody>
                    <tr>
                      <th className="user-details">
                        {localesData?.EDITPROFILE?.EDIT_PROFILE}
                        <br />
                        <span
                          className="profile-edit"
                          onClick={() => handleImageModal()}
                        >
                          {localesData?.EDITPROFILE?.CHANGE_PROFILE}
                        </span>
                      </th>
                      <td>
                        <Box className="profile-image-name-wrap">
                          <Box
                            className="profile-image-wrap"
                            onClick={() => handleImageModal()}
                          >
                            <img
                              className="profile-image"
                              src={
                                cropImage?.filePath
                                  ? Config?.countryMediaURL +
                                    cropImage?.filePath
                                  : userData?.data?.Media?.filePath
                                  ? Config?.countryMediaURL +
                                    userData?.data?.Media?.filePath
                                  : profileImage
                              }
                              alt="profile-image"
                            />
                            <EditButton className="add-button" />
                          </Box>
                        </Box>
                      </td>
                    </tr>
                    <tr>
                      <th className="user-details">
                        {localesData?.EDITPROFILE?.USER_DETAILS}
                        <br />
                        <span
                          className="profile-edit"
                          onClick={() =>
                            handleClickUser("useDetails", userData?.data)
                          }
                        >
                          {localesData?.EDITPROFILE?.EDIT}
                        </span>
                      </th>
                      <td>
                        {localesData?.register?.first_name}:{" "}
                        <span>{userData?.data?.firstName}</span>
                      </td>
                    </tr>
                    <tr>
                      <th></th>
                      <td>
                        {localesData?.register?.last_name}:{" "}
                        <span>{userData?.data?.lastName}</span>
                      </td>
                    </tr>
                    <tr>
                      <th></th>
                      <td>
                        {localesData?.register?.dob}:{" "}
                        <span>
                          {userData?.data?.dob
                            ? moment(userData?.data?.dob).format("DD/MM/YYYY")
                            : ""}
                       
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <th></th>
                      <td>
                        {localesData?.register?.phonenumber}:{" "}
                        <span>
                          {userData?.data?.Country?.phoneCode
                            ? `${"+" + userData?.data?.Country?.phoneCode} `
                            : ""}
                          {userData?.data?.phone}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <th></th>
                      <td>
                        {localesData?.register?.email}:{" "}
                        <span>{userData?.data?.username}</span>
                      </td>
                    </tr>
                    <tr>
                      <th></th>
                      <td>
                        {localesData?.register?.USER_NAME}:{" "}
                        <span>{userData?.data?.nickName}</span>
                      </td>
                    </tr>
                    <tr>
                      <th></th>
                      <td>
                        {localesData?.register?.GENDER}:{" "}
                        <span>{fetchGender(userData?.data?.gender)}</span>
                      </td>
                    </tr>

                    <tr>
                      <th></th>
                      <td>
                        {localesData?.register?.country_of_residence}:
                        <span>
                          {" "}
                          {userData?.data?.address?.Country?.country}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <th></th>
                      <td>
                        {localesData?.register?.state}:
                        <span> {userData?.data?.address?.State?.state}</span>
                      </td>
                    </tr>

              
                    <tr>
                      <th>
                        {localesData?.register?.password} <br />
                        <span
                          className="profile-edit"
                          onClick={() =>
                            handleClickUser("password", userData?.data)
                          }
                        >
                          {localesData?.EDITPROFILE?.EDIT}
                        </span>
                      </th>
                      <td>***********</td>
                    </tr>
                    {Config.release == "AU" ? (
                      <tr>
                        <th>
                          {localesData?.EDITPROFILE?.SPORTS} <br />
                          <span
                            className="profile-edit"
                            onClick={() =>
                              handleClickUser("sports", userData?.data)
                            }
                          >
                            {localesData?.EDITPROFILE?.EDIT}
                          </span>
                        </th>
                        <td>{sport?.toString()}</td>
                      </tr>
                    ) : (
                      <></>
                    )}
                    {fetchNotificationData(userData?.data)}
                    {Config.release == "AU" ? (
                      <>
                        <tr>
                          <th>
                            {localesData?.EDITPROFILE?.BOOKMAKER_ACCOUNTS}{" "}
                            <br />
                            <span
                              className="profile-edit"
                              onClick={() =>
                                handleClickUser("bookmakers", userData?.data)
                              }
                            >
                              {localesData?.EDITPROFILE?.EDIT}
                            </span>
                          </th>
                          <td>{BookMaker?.toString()}</td>
                        </tr>
                      </>
                    ) : (
                      <></>
                    )}
             
                    <tr>
                      <th>
                        {" "}
                        {localesData?.EDITPROFILE?.DELETE_ACCOUNT} <br />
                        <span
                          className="profile-edit"
                          onClick={() => handleModal()}
                        >
                          {localesData?.EDITPROFILE?.DELETE}
                        </span>
                      </th>
                    </tr>
                  </tbody>
                </table>
                <Box className="profile-logout-delete">
                  {Config.release == "AU" && (
                    <Button
                      disableElevation
                      disableFocusRipple
                      disableRipple
                      className="blackbook"
                      onClick={() => navigate("/blackbook")}
                    >
                      BlackBook
                    </Button>
                  )}

                  <Button
                    disableElevation
                    disableFocusRipple
                    disableRipple
                    className="blackbook"
                    onClick={() => navigate("/smartbook")}
                  >
                    Smartbook
                  </Button>
                </Box>
                <Box className="profile-logout-delete">
                  <Button
                    disableElevation
                    disableFocusRipple
                    disableRipple
                    className="logout"
                    onClick={() => handleLogout()}
                  >
                    {localesData?.auth_btn?.logout}
                  </Button>
                </Box>
              </Box>
            )} */}
          </Box>
        ) : (
          <Box className="profile-details">
            <h3>{localesData?.EDITPROFILE?.MY_PROFILE}</h3>
            <Box className="profile-details-wrap">
              <Box>
                <Typography
                  className="selected-title-wrap"
                  onClick={() => setIsCollepseOpen(prev => !prev)}
                >
                  <RightArrow
                    className={`collepse-arrow ${
                      isCollepseOpen ? "open-arrow" : ""
                    }`}
                  />
                  <span className="selected-title regular-font semibold-font">
                    {subMenuName}
                    {subName ? (
                      <span className="regular-small-font"> ({subName})</span>
                    ) : (
                      <></>
                    )}
                  </span>
                </Typography>
                <Collapse in={isCollepseOpen}>
                  <ProfileMenu
                    selectedMenu={selectedMenu}
                    handleChangeMenu={handleChangeMenu}
                  />
                </Collapse>
              </Box>
              <Box className="profile-main-section">
                {fetchSelectedComponenet()}
              </Box>
            </Box>
          </Box>
        )}
      </Box>

      <OurPartner />
      <DialogBox
        open={open}
        onClose={handleClose}
        handleClose={handleClose}
        title={false}
        content={
          <>
            <Box className="delete-dialogbox">
              <Typography className="delete-title">
                Are you sure you want to delete your account?
              </Typography>
              <Box className="delete-btn">
                <Button
                  disableElevation
                  disableFocusRipple
                  disableRipple
                  className="btn-cancel"
                  onClick={() => {
                    handleuserDelete(userData?.data?.id);
                  }}
                >
                  Yes
                </Button>
                <Button
                  disableElevation
                  disableFocusRipple
                  disableRipple
                  className="btn-save"
                  onClick={() => handleClose()}
                >
                  No
                </Button>
              </Box>
            </Box>
          </>
        }
        closeIcon={false}
      />
    </Box>
    <UploadImageModal
      open={imageModalOpen}
      handleClose={handleImageClose}
      title={"Upload Profile Picture"}
      closeIcon={false}
      getImage={getImage}
      //  listingFunction={() =>
      //    setTimeout(() => {
      //      getEventByID(stepperCount);
      //    }, 3000)
      //  }
    />
    {/* <ToastContainer
      position="bottom-center"
      autoClose={3000}
      hideProgressBar
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="colored"
    /> */}
  </>);
}

export default ProfilePageV2;
