import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Typography,
  RadioGroup,
  Radio,
  FormControlLabel,
  TextField,
  Checkbox
} from "@mui/material";
import { ReactComponent as Checked } from "src/assets/images/billedChecked.svg";
import { ReactComponent as UnChecked } from "src/assets/images/billedUnChecked.svg";
import { ReactComponent as CheckBoxChecked } from "src/assets/images/checkBoxChecked.svg";
import { ReactComponent as CheckboxUnChecked } from "src/assets/images/checkboxUnChecked.svg";
import Cvvimage from "../../../../assets/images/cvvimage.png";
import { ToastContainer, toast } from "react-toastify";
import { Formik } from "formik";
import * as Yup from "yup";
import { IntlContext } from "src/App";
import { Config, fetchFromStorage } from "src/helpers/context";
import { fetchRestrictedRoute } from "src/helpers/store/Actions/RestrictedRoute";
import { useDispatch } from "react-redux";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import Loader from "src/components/Loader";
import { fetchSubscriptionData } from "src/helpers/store/Actions/SubscriptionData";
import moment from "moment";
import "./paymentModal.scss";
import { useNavigate } from "react-router";
import { setApiMessage } from "src/helpers/commonFunctions";

const PaymentModal = ({
  paymentModalOpen,
  setPaymentModalOpen,
  handleClose,
  fetchGetPlanDetails,
  planId,
  planPrice,
  planDetails,
  compId,
  handleSupportedTeamModal,
  couponApply,
  couponCode,
  couponCodeDetails,
  handlePaymentStatus
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const [paymentMode, setPaymentMode] = useState("");
  const [cardsave, setCardSave] = useState(false);
  // const [CardSaveLoading, setCardSaveLoading] = useState(false);
  const [subscribeLoading, setSubscribeLoading] = useState(false);
  const [cardList, setCardList] = useState([]);
  const [cardLoader, setCardLoader] = useState(false);
  const [selectedCard, setSelectedCard] = useState("");

  useEffect(() => {
    fetchGetCardDetails();
  }, [paymentModalOpen]);

  const fetchGetCardDetails = async () => {
    setCardLoader(true);
    try {
      const { status, data } = await axiosInstance.get(
        `/subscription/get-card`
      );
      if (status === 200) {
        setCardLoader(false);
        const cardDetails = data?.card;
        setCardList(cardDetails);
      }
    } catch (error) {
      setCardLoader(false);
    }
  };

  const handleCardSave = event => {
    setCardSave(event?.target?.checked);
  };

  const handlePaymentModeChange = (e, data) => {
    if (e?.target?.checked) {
      setSelectedCard(data);
      setPaymentMode(e?.target?.value);
    } else {
      setSelectedCard("");
      setPaymentMode("");
    }
  };

  const handlecardRemove = async id => {
    try {
      const { status, data } = await axiosInstance.delete(
        `/subscription/delete-card/${id}`,
        {
          body: {
            type: "web"
          }
        }
      );
      if (status === 200) {
        fetchGetCardDetails();
        setSelectedCard("");
      } else {
        setSelectedCard("");
      }
    } catch (error) {
      setCardLoader(false);
      setSelectedCard("");
    }
  };

  const fetchSubscriptionPaymentData = async () => {
    try {
      const { status, data } = await axiosInstance.get(`/user/get-user`);
      if (status === 200) {
        if (Config.release == "IN") {
          data["oddfixtures"] = false;
        }
        dispatch(fetchSubscriptionData(data));
      } else {
        dispatch(fetchSubscriptionData(undefined));
      }
    } catch (err) {
      dispatch(fetchSubscriptionData(undefined));
    }
  };

  const validationSchema = Yup.object().shape({
    cardname: paymentMode
      ? Yup.string()
      : Yup.string().required(localesData?.validation?.required_message),
    cardNumber: paymentMode
      ? Yup.string()
      : Yup.string()
          .min(19, "Card number must be at most 16 characters")
          .max(19, "Card number must be at most 16 characters")
          .required("Card number is required"),
    expirationDate: paymentMode
      ? Yup.string()
      : Yup.string()
          .required("Expiration date is required")
          .typeError("Not a valid expiration date. Example: MM/YYYY")
          .max(7, "Not a valid expiration date. Example: MM/YYYY")
          .matches(
            /([0-9]{2})\/([0-9]{4})/,
            "Not a valid expiration date. Example: MM/YYYY"
          )
          .test(
            "is-future",
            "Card expiration date should be in the future",
            function(value) {
              if (!value) return false;
              const currentDate = new Date();
              const [month, year] = value.split("/");
              const cardExpirationDate = new Date(
                parseInt(year),
                parseInt(month) - 1
              );
              return cardExpirationDate > currentDate;
            }
          )
          .test(
            "not-over-100",
            "Card expiration date should not exceed 100 years in the future",
            function(value) {
              if (!value) return false;
              const currentDate = new Date();
              const [month, year] = value.split("/");
              const cardExpirationDate = new Date(
                parseInt(year),
                parseInt(month) - 1
              );
              return (
                cardExpirationDate.getFullYear() <=
                currentDate.getFullYear() + 100
              );
            }
          ),
    cvv: paymentMode
      ? Yup.string()
      : Yup.string()
          .min(3)
          .max(3)
          .required("CVV number is required")
  });

  return (<>
    <Box>
      <Box className="payment-select">
        <RadioGroup
          aria-label="payment"
          name="payment"
          className="payment-radioGroup"
          value={Number(paymentMode)}
        >
          {cardLoader && (
            <div className="allsport-loader-center">
              <Loader />
            </div>
          )}
          {!cardLoader &&
            (cardList?.length > 0 ? (
              cardList?.map(item => {
                return (
                  <>
                    <Box className="payment-card-flex">
                      <FormControlLabel
                        key={item.id}
                        className="save-card"
                        value={Number(item.id)}
                        onChange={e => handlePaymentModeChange(e, item)}
                        control={
                          <Checkbox
                            size="small"
                            icon={<UnChecked className="radio-icon" />}
                            checkedIcon={<Checked className="radio-icon" />}
                            disableRipple
                            checked={item?.id == paymentMode}
                          />
                        }
                        label={
                          <Box className="save-payment-card-label">
                            <Box>
                              <Typography className="bold">
                                {item?.brand}
                                <span>****{item?.last4}</span>
                              </Typography>
                              <Typography>{item?.cardHolderName}</Typography>
                              <Typography>
                                Expiry - {item?.cardExp}
                              </Typography>
                            </Box>
                          </Box>
                        }
                      />
                      <Box>
                        <span
                          className={
                            item.is_default === 1
                              ? "disabled"
                              : "cursor-pointer"
                          }
                          onClick={() => {
                            if (item && item.is_default !== 1) {
                              handlecardRemove(item.id);
                            }
                          }}
                        >
                          Remove
                        </span>
                      </Box>
                    </Box>
                  </>
                );
              })
            ) : (
              <></>
            ))}
          {/* <FormControlLabel
              value="Credit"
              control={
                <Radio
                  size="small"
                  icon={<UnChecked className="radio-icon" />}
                  checkedIcon={<Checked className="radio-icon" />}
                  disableRipple
                />
              }
              label={
                <Box className="payment-label">
                  <Typography className="label">
                    Credit Or Debit Card
                  </Typography>
                  <Box className="card-section">
                    <img src={MasterCard} alt="card" />
                    <img src={Visa} alt="card" />
                    <img src={AmericanExpress} alt="card" />
                  </Box>
                </Box>
              }
            /> */}
          {/* <FormControlLabel
              value="PayPal"
              control={
                <Radio
                  size="small"
                  icon={<UnChecked className="radio-icon" />}
                  checkedIcon={<Checked className="radio-icon" />}
                  disableRipple
                />
              }
              label=<Box className="payment-label">
                <Typography className="label">PayPal</Typography>
                <Box className="card-section">
                  <img src={PayPal} alt="card" />
                </Box>
              </Box>
            /> */}
        </RadioGroup>
      </Box>
      <Box className="card-details">
        <Formik
          initialValues={{
            cardname: selectedCard ? selectedCard?.cardHolderName : "",
            cardNumber: "",
            expirationDate: selectedCard
              ? moment(selectedCard?.cardExp).format("MM/YYYY")
              : "",
            cvv: ""
          }}
          enableReinitialize={true}
          validationSchema={validationSchema}
          // onSubmit={async requestData => {
          //   console.log("requestData", requestData);

          // }}
          onSubmit={async (requestData, { resetForm }) => {
            setSubscribeLoading(true);

            const expirationDate = requestData?.expirationDate;
            const parts = expirationDate.split("/");
            let formattedDate = "";
            if (parts.length === 2) {
              const month = parts[0];
              const year = parts[1];
              formattedDate = `${year}-${month}`;
            }
            // let cardPayload = {
            //   card: {
            //     card_holder_name: requestData?.cardname,
            //     card_number: requestData?.cardNumber?.replace(/\s/g, ""),
            //     card_exp: formattedDate,
            //     cvv: requestData?.cvv
            //   }
            // };
            if (compId) {
              let cardPayload = {
                platform: "web",
                cardId: Number(paymentMode)
              };
              let payload = {
                platform: "web",
                isFeatured: cardsave,
                card: {
                  card_holder_name: requestData?.cardname,
                  card_number: requestData?.cardNumber?.replace(/\s/g, ""),
                  card_exp: formattedDate,
                  cvv: requestData?.cvv
                }
              };

              try {
                let passApi = `/tipping/payment/${compId}`;
                const { status, data } = await axiosInstance.post(
                  passApi,
                  paymentMode ? cardPayload : payload
                );
                handlePaymentStatus(true);
                if (status === 200) {
                  setSubscribeLoading(false);
                  setApiMessage("success", data?.message);
                  setCardSave(false);
                  setPaymentModalOpen(false);
                  setSelectedCard("");
                  let timer = setTimeout(() => resetForm(requestData), 3000);
                  handleSupportedTeamModal();
                } else {
                  setSubscribeLoading(false);
                  setPaymentModalOpen(false);
                  setCardSave(false);
                  setSelectedCard("");
                }
              } catch (error) {
                handlePaymentStatus(false);
                setSubscribeLoading(false);
                setCardSave(false);
                setSelectedCard("");
                setApiMessage("error", error?.response?.data?.message);
                setPaymentModalOpen(false);
              }
            } else {
              let cardPayload = {
                plan_id: planId,
                plateform: "web",
                card_id: paymentMode,
                couponCode: couponCode ? couponCode : ""
              };
              let payload = {
                plan_id: planId,
                plateform: "web",
                isFeatured: cardsave,
                couponCode: couponCode ? couponCode : "",
                card: {
                  card_holder_name: requestData?.cardname,
                  card_number: requestData?.cardNumber?.replace(/\s/g, ""),
                  card_exp: formattedDate,
                  cvv: requestData?.cvv
                }
              };
              const isUpgrade = planDetails?.some(
                item => item?.purchasedPlan === true
              );
              try {
                let passApi = isUpgrade
                  ? "/subscription/upgrade-plan"
                  : "/subscription/subscribe-plan";
                let method = isUpgrade ? "put" : "post";
                const { status, data } = await axiosInstance[method](
                  passApi,
                  paymentMode ? cardPayload : payload
                );
                if (status === 200) {
                  // if (cardsave) {
                  //   setCardSaveLoading(true);
                  //   try {
                  //     const { status, data } = await axiosInstance.post(
                  //       "/subscription/create-card",
                  //       cardPayload
                  //     );
                  //     if (status === 200) {
                  //       setCardSaveLoading(false);
                  //       setCardSave(false);
                  //     } else {
                  //       setCardSaveLoading(false);
                  //       setCardSave(false);
                  //     }
                  //   } catch (error) {
                  //     setCardSaveLoading(false);
                  //     setCardSave(false);
                  //   }
                  // }
                  setSubscribeLoading(false);
                  setApiMessage("success", data?.message);
                  setCardSave(false);
                  setPaymentModalOpen(false);
                  navigate("/mySubscription");
                  setSelectedCard("");
                  fetchGetPlanDetails();
                  fetchSubscriptionPaymentData();
                  let timer = setTimeout(
                    () => resetForm(requestData),
                    // setInterestedData([]),

                    3000
                  );
                } else {
                  setSubscribeLoading(false);
                  setPaymentModalOpen(false);
                  setCardSave(false);
                  setSelectedCard("");
                }
              } catch (error) {
                setSubscribeLoading(false);
                setCardSave(false);
                setSelectedCard("");
                setApiMessage("error", error?.response?.data?.message);
                setPaymentModalOpen(false);
              }
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values,
            setFieldValue
          }) => (
            <form onSubmit={handleSubmit}>
              <Box className="card-form">
                <Box className="card-info mb-19">
                  <Typography className="textfield-text">
                    Card Number<span className="star">*</span>
                  </Typography>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    className="details-textfield"
                    name="cardNumber"
                    error={Boolean(touched?.cardNumber && errors?.cardNumber)}
                    helperText={touched?.cardNumber && errors?.cardNumber}
                    inputProps={{ maxLength: 19 }}
                    disabled={paymentMode}
                    onChange={e => {
                      handleChange(e);
                      // setCardUpdate({
                      //   ...cardUpdate,
                      //   card_token: e.target.value
                      // });
                      setFieldValue(
                        "cardNumber",
                        e?.target?.value
                          .replace(/[^\dA-Z*]/g, "")
                          .replace(/(.{4})/g, "$1 ")
                          .trim()
                      );
                    }}
                    value={values?.cardNumber}
                    onKeyPress={e => {
                      if (!/[0-9 ]/.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                    onBlur={e => {
                      handleBlur(e);
                      // updateCardDetails(
                      //   "cardNumber",
                      //   values?.cardNumber
                      //     .replace(/[^\dA-Z*]/g, "")
                      //     .replace(/(.{4})/g, "$1 ")
                      //     .trim()
                      // );
                    }}
                  />
                </Box>
                <Box className="date-cvv-wrap mb-19">
                  <Box className="card-info date-card-info">
                    <Typography className="textfield-text">
                      Expiry Date<span className="star">*</span>
                    </Typography>
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      className="details-textfield"
                      placeholder="mm/yyyy"
                      error={Boolean(
                        touched?.expirationDate && errors?.expirationDate
                      )}
                      helperText={
                        touched?.expirationDate && errors?.expirationDate
                      }
                      name="expirationDate"
                      disabled={paymentMode}
                      value={values?.expirationDate}
                      onChange={e => {
                        setFieldValue(
                          "expirationDate",
                          e?.target?.value
                            .replace(/[^0-9]/g, "") // To allow only numbers
                            .replace(/^([2-9])$/g, "0$1") // To handle 3 > 03
                            .replace(/^(1{1})([3-9]{1})$/g, "0$1/$2") // 13 > 01/3
                            .replace(/^0{1,}/g, "0") // To handle 00 > 0
                            .replace(
                              /^([0-1]{1}[0-9]{1})([0-9]{1,4}).*/g,
                              "$1/$2"
                            ) // To handle 113 > 11/3
                        );
                        // setCardUpdate({
                        //   ...cardUpdate,
                        //   card_exp: e.target.value
                        // });
                      }}
                      inputProps={{ maxLength: 7 }}
                      onBlur={e => {
                        handleBlur(e);
                        // updateCardDetails(
                        //   "expirationDate",
                        //   values?.expirationDate
                        // );
                      }}
                    />
                  </Box>
                  <Box className="card-info cvv-card-info">
                    <Typography className="textfield-text cvv-text">
                      CVV <span className="star">*</span>
                      <img src={Cvvimage} alt="cvv" />
                    </Typography>
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      className="details-textfield"
                      name="cvv"
                      type="password"
                      error={Boolean(touched?.cvv && errors?.cvv)}
                      disabled={paymentMode}
                      helperText={touched?.cvv && errors?.cvv}
                      value={values.cvv}
                      onChange={e => {
                        const re = /^[0-9\b]+$/;
                        if (
                          e.target.value === "" ||
                          re.test(e.target.value)
                        ) {
                          handleChange(e);
                        }
                        // setCardUpdate({
                        //   ...cardUpdate,
                        //   cvv: e.target.value
                        // });
                      }}
                      inputProps={{ maxLength: 3 }}
                      onBlur={e => {
                        handleBlur(e);
                        // updateCardDetails("cvv", values?.cvv);
                      }}
                    />
                  </Box>
                </Box>
                <Box className="card-info mb-19">
                  <Typography className="textfield-text">
                    Name on Card<span className="star">*</span>
                  </Typography>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    className="details-textfield"
                    error={Boolean(touched.cardname && errors.cardname)}
                    helperText={touched.cardname && errors.cardname}
                    name="cardname"
                    value={values.cardname}
                    disabled={paymentMode}
                    onChange={e => {
                      handleChange(e);
                      // setCardUpdate({
                      //   ...cardUpdate,
                      //   card_holder_name: e.target.value
                      // });
                    }}
                    onBlur={e => {
                      handleBlur(e);
                      // updateCardDetails("cardname", values?.cardname);
                    }}
                  />
                </Box>
                <Box className="checkBox-wrap">
                  <FormControlLabel
                    className="documentsRead-check"
                    control={
                      <Checkbox
                        className="documentsRead-checkbox"
                        checked={cardsave}
                        icon={<CheckboxUnChecked className="radio-icon" />}
                        checkedIcon={
                          <CheckBoxChecked className="radio-icon" />
                        }
                        onChange={e => handleCardSave(e)}
                        color="primary"
                      />
                    }
                    label={
                      <Typography>Save the card for future use</Typography>
                    }
                  />
                </Box>
                <Box className="total-amount">
                  <Typography className="bold">Total Cost:</Typography>
                  <Typography>
                    {couponApply
                      ? "$" +
                        (
                          Number(planPrice ?? 0) -
                          Number(couponCodeDetails?.discountAmountPrize ?? 0)
                        )?.toFixed(2)
                      : "$" + Number(planPrice)?.toFixed(2)}
                  </Typography>
                </Box>
                <Box className="subscribe-wrap-btn">
                  <Button
                    variant="contained"
                    type="submit"
                    className="subscribe-btn"
                    disabled={subscribeLoading}
                  >
                    {subscribeLoading
                      ? "Loading..."
                      : compId
                      ? "Pay to create a competition"
                      : "Subscribe"}
                  </Button>
                </Box>
                {!compId && (
                  <Box className="subscribe-note">
                    <Typography>
                      Plan renews automatically. You can change or cancel
                      anytime
                    </Typography>
                  </Box>
                )}
              </Box>
            </form>
          )}
        </Formik>
      </Box>
    </Box>
  </>);
};

export default PaymentModal;
